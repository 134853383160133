import React, { useEffect, useState } from 'react'
import './index.scss'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Modal from './components/modal/Modal'
import Home from './pages/home/Home'
import Contact from './pages/contact/Contact'
import APropos from './pages/a-propos/APropos'
import Explore from './pages/explore/Explore'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom' //react router pour l'utilisation classique
// import { HashRouter as Router, Route, Routes } from 'react-router-dom' //Hashrouter pour le github pages
import { useTranslation } from 'react-i18next'
import Accompagnement from './pages/accompagnement/Accompagnement'
import LegalNotice from './pages/legal-notice/LegalNotice'
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy'
import CustomAlert from './components/alert/CustomAlert'
import QuestionsAsk from './components/questionsAsk/QuestionsAsk'
import Temoignages from './components/temoignages/Temoignages'
import Ressources from './components/ressources/Ressources'
import ReactGA from 'react-ga4'

const GA_TRACKING_ID = 'G-VYHM8CG500' // Remplacez par l'identifiant ga4

function App() {
  const cookieEnabled = localStorage.getItem('cookieEnabled')

  const [cookieModalShow, setcookieModalShow] = useState(!cookieEnabled)
  const { t } = useTranslation()
  const handleCookieAccept = () => {
    if (navigator.cookieEnabled) {
      document.cookie =
        'cookieEnabled=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/'
    }
    localStorage.setItem('cookieEnabled', 'true')
    setcookieModalShow(false)
  }

  const handleCookieRefuse = () => {
    localStorage.setItem('cookieEnabled', 'false')
    setcookieModalShow(false)
  }

  useEffect(() => {
    ReactGA.initialize(GA_TRACKING_ID)
    ReactGA.send({
      hitType: 'pageview',
      page: '/landingpage',
      title: 'Landing Page',
    })
  }, [])

  return (
    <div className="App">
      <Router>
        <Header />
        <CustomAlert></CustomAlert>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="a-propos" element={<APropos />} />
          <Route path="explore" element={<Explore />} />
          <Route path="suivi" element={<Accompagnement />} />
          <Route path="contact" element={<Contact />} />
          <Route path="legal-notice" element={<LegalNotice />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="ressources" element={<Ressources />} />
          <Route path="faq" element={<QuestionsAsk />} />
        </Routes>
        <QuestionsAsk asCTA={true}></QuestionsAsk>
        <Temoignages></Temoignages>
        <Ressources asCTA={true}></Ressources>
        <Footer />
        {cookieModalShow && (
          <Modal
            show={cookieModalShow}
            setModalShow={setcookieModalShow}
            confirmAction={handleCookieAccept}
            leaveAction={handleCookieRefuse}
            description={t('cookies_legacy')}
            accept={'AGREE'}
            refused={'NO THANKS'}
            cookieModal={true}
          />
        )}
      </Router>
    </div>
  )
}

export default App
