import { Link } from 'react-router-dom'
import './Footer.scss'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as Facebook } from '../../assets/icons/facebook.svg'
import { ReactComponent as Instagram } from '../../assets/icons/instagram.svg'
import { ReactComponent as Linkedin } from '../../assets/icons/linkedin.svg'
import { ReactComponent as Youtube } from '../../assets/icons/youtube.svg'
import avatarCoucou from '../../assets/avatar/avatar_coucou_no_text.png'

import ReactGA from 'react-ga4'

function Footer() {
  const { t } = useTranslation()

  const buildGoogleMapsUrl = () => {
    const address = '45.057807523909155, -0.3370479536023311'
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`
  }
  const handleClick = (link) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    link &&
      ReactGA.event({
        category: 'Page Links',
        action: 'Click',
        label: link,
      })
  }

  return (
    <footer className="footer_container">
      <div className="container_adresse">
        <img
          className="illustration_1"
          src={avatarCoucou}
          alt={`avatar de delpfine qui salut de la main`}
          height={300}
        />
        <div>
          <h2>{t('contact_us')}</h2>
          <p className="sub_text">
            Retrouve moi sur mes différents réseaux sociaux !
          </p>
        </div>
        <div className="adresse">
          {/* <FontAwesomeIcon icon={faMapMarkerAlt} /> */}
          <div className="networks_links">
            <a className="facebook" href="">
              <Facebook />
            </a>
            <a className="instagram" href="">
              <Instagram />
            </a>
            <a className="linkedin" href="">
              <Linkedin />
            </a>
            <a
              className="youtube"
              href="https://www.youtube.com/@DelPfine-coaching"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Youtube />
            </a>
          </div>

          <div>
            <p className="sub_text">Par téléphone</p>
            {/* <p>112 rue de la petite jonquille</p>
            <p>12345 Belle Fleur</p> */}
          </div>
          <a href="tel:0767944221">07 67 94 42 21</a>
          <div>
            <p className="sub_text">Par email</p>
          </div>
          <a href="mailto:contact@delpfine.com">contact@delpfine.com</a>
        </div>
        {/* <div className="container_gmaps">
          <a
            href={buildGoogleMapsUrl()}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('view_on_google_maps')}
          </a>
          <iframe
            title="gmap"
            className="iframe_map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d176.14875712913528!2d-0.3371988278502826!3d45.05779970829744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4800219fccb9ab43%3A0x9b7959a317242064!2s5%20Le%20Bourg%20Bis%2C%2033620%20Marcenais!5e0!3m2!1sfr!2sfr!4v1714301142743!5m2!1sfr!2sfr"
            style={{ border: '0' }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div> */}
      </div>
      <div className="container_bottom_footer">
        <a onClick={() => handleClick()}>HAUT DE PAGE ^</a>
        <div className="container_links">
          <Link to="/" onClick={() => handleClick('/')}>
            {t('home')}
          </Link>
          <Link to="/a-propos" onClick={() => handleClick('/a-propos')}>
            {t('about')}
          </Link>
          <Link to="/contact" onClick={() => handleClick('/contact')}>
            {t('contact')}
          </Link>
        </div>
        <div className="container_legals">
          <Link to="/site-plan" onClick={() => handleClick('/site-plan')}>
            {t('site_plan')}
          </Link>
          <Link to="/legal-notice" onClick={() => handleClick('/legal-notice')}>
            {t('legal_mentions')}
          </Link>
          <Link
            to="/privacy-policy"
            onClick={() => handleClick('/privacy-policy')}
          >
            {t('privacy_policy')}
          </Link>
        </div>
        <div className="copyright">© 2024 DelpFine</div>
      </div>
    </footer>
  )
}

export default Footer
