import CustomAlert from '../alert/CustomAlert'
import './Newsletter.scss'
import React, { useState } from 'react'

function Newsletter() {
  // const GOOGLE_API_KEY = 'AIzaSyBcs3qsdWpw5PTXLMTVIkYs3YkhJngBb4I'
  const macro_google_id =
    'https://script.google.com/macros/s/AKfycbw_WaWeSOnpoPnVGi8OqsmUUxv1IODvW1hXSjzP8ifMm471op1cghFBPclq1ZpqwByI/exec'

  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isUserSubscribe, setIsUserSubscribe] = useState(false)

  const [alertConfig, setAlertConfig] = useState({
    open: false,
    severity: '',
    message: '',
    icon: '',
  })

  function handleInput(event) {
    setIsEmailValid(true)
    setIsUserSubscribe(false)
    setEmail(event.target.value)
  }

  async function handleSubmit(event) {
    event.preventDefault()

    if (email === '' || !/\S+@\S+\.\S+/.test(email)) {
      setIsEmailValid(false)
      setAlertConfig({
        open: true,
        severity: 'error',
        message: "Cet email n'est pas valide 🙄",
        icon: 'error',
      })
    } else {
      try {
        const response = await fetch(macro_google_id, {
          redirect: 'follow',
          method: 'POST',
          body: JSON.stringify({ email }),
          headers: { 'Content-Type': 'text/plain;charset=utf-8' },
        })
        if (response.ok) {
          setIsUserSubscribe(true)
          setAlertConfig({
            open: true,
            severity: 'success',
            message: 'Merci de ton abonnement 😊',
            icon: 'success',
          })
        } else {
          setIsUserSubscribe(false)
          setAlertConfig({
            open: true,
            severity: 'error',
            message: 'Il y a eu une erreur. Merci de tenter à nouveau.',
            icon: 'error',
          })
        }
      } catch (error) {
        setIsUserSubscribe(false)
        setAlertConfig({
          open: true,
          severity: 'error',
          message: 'Il y a eu une erreur. Merci de tenter à nouveau.',
          icon: 'error',
        })
      }
      setIsEmailValid(true)
      setEmail('')
    }
  }

  return (
    <>
      <div className="newsletter_container">
        <div className="newsletter_content">
          <div className="sub_container_light">
            <div className=" text-align-center">
              <h2>Newsletter</h2>
              <p className="sub_text">
                Abonnez-vous à ma newsletter et recevez des conseils, des infos
                et des offres privilégiées !
              </p>
            </div>
            <div className="sub_container_light text-align-center">
              {!isEmailValid ? <p>Cet email n'est pas valide 🙄</p> : null}
              {isUserSubscribe ? <p>Merci de ton abonnement 😊</p> : null}
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={handleInput}
                  style={{
                    padding: '10px',
                    fontSize: '16px',
                    border: '2px solid #ddd',
                    borderRadius: '12px',
                  }}
                />
                <button type="submit" style={{}}>
                  J'y suis
                </button>
                <p className="sub_sub_text">
                  * En communicant mon e-mail j'accepte de recevoir vos
                  communications et confirme avoir pris connaissance de votre
                  politique de confidentialité et mentions légales
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CustomAlert
        openAlert={alertConfig.open}
        onClose={() => setAlertConfig({ ...alertConfig, open: false })}
        severity={alertConfig.severity}
        icon={alertConfig.icon}
      >
        {alertConfig.message}
      </CustomAlert>
    </>
  )
}

export default Newsletter
