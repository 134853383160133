import { Link } from 'react-router-dom'
import './Temoignages.scss'
import React from 'react'

function Temoignages() {
  const handleRedirect = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  let data = [
    {
      img: 'image1.png',
      prenomVille: 'Clémentine',
      date: 'Avril 2024',
      temoignage:
        "Sache que ton accompagnement m'apporte également beaucoup. \n J'adore ce combo coaching / accompagnement hypersensibilité avec l'hypnose, \n qui me permet de conscientiser mes propres ressources, de les mobiliser, de me poser les bonnes questions et retrouver beaucoup de clarté et de confiance. \n Ces séances m'aident à libérer mes émotions et me boostent en énergie ! \n\n\n Je te remercie pour ton regard si bienveillant, ton écoute active et sans jugement et tous tes éclairages si judicieux. \n Je me suis tout de suite sentie en confiance et tu as su trouver le format idéal, qui me permet à la fois de me déposer et me libérer tout en me poussant vers l'action ! \n Depuis qu'on a recommencé nos séances, le fait de poser mes objectifs avec toi me donne envie d'avancer, de me surpasser. \n C'est comme si j'y croyais davantage et je me sens de plus en plus capable d'y arriver.\n Comme si je prenais davantage conscience de mes forces et de ma valeur. \n Cela m'apporte également beaucoup de douceur envers moi-même, \n et me permet de savourer chaque petite goutte d'eau de mon océan avec davantage de fierté et de reconnaissance. \n\n\n Nulle doute que tu es faite pour l'accompagnement ! \n Je te souhaite le meilleur dans ce projet de cœur, qui te permettra de mettre en lumière tous les trésors que tu as en toi. ✨\n Ne doute pas de ta réussite, \n car ce projet n'est autre que la continuité de toi-même et de tes talents naturels.",
    },
    {
      img: 'image2.png',
      prenomVille: 'Jérôme',
      date: 'Septembre 2023',
      temoignage:
        "Cette exploration m'a permis de confirmer que mon profil avait bien des spécificités neuro atypiques. Ce qui me permet de mieux comprendre mon fonctionnement et du coup lâcher prise sur certaines choses sur lesquelles parfois je pouvais un peu m'acharner \n\n J'ai apprécié l'engagement dont tu as fait preuve et la restitution correcte de mon interview. Le partage des situations avec des réactions un peu similaires (on se sent toujours moins seul avec des personnes qui fonctionnent \"un peu comme nous`\" d'ailleurs je me demande quel est le pourcentage de multi potentiel dans la population. ?",
    },
    {
      img: 'image3.png',
      prenomVille: 'Kathleen',
      date: 'Juin 2023',
      temoignage:
        "Cette exploration de mon fonctionnement m'a apporté une meilleure connaissance de soi dans un moment de prendre du temps pour soi. Une explication sur ma bizarrerie de l'enfance. J'ai moins de jugement sur moi aujourd'hui mais je me dis que enfant savoir cela m'aurait permis de mieux me comprendre et de me pardonner mon comportement au lieu de le juger si mal. Je me serais sentie comprise. Du coup, j'ai beaucoup d'indulgence pour l'enfant que j'étais et je regarde d'un œil différent celle d'aujourd'hui. \n J'ai apprécié ta bonté, ta gentillesse. Tu es adorable et tu as l'écoute alors on te fait confiance facilement. \n\n J'ai vraiment trouvé rigolo les questions. \n\n Et de prendre du temps pour moi ce que j'ai du mal a faire. D'ailleurs depuis toi et le test en juin. Je me suis inscrite à des cours à l'université pour moi, pour mon plaisir… je suis séparée de mon conjoint ( j'ai retrouvé en sérénité)… j'envisage même de reprendre la course sans avoir saisi encore l'opportunité de me lancer. \n\n Je pense que certaines choses qu'on s'est dites on fait des liens ou des déclics intéressants.",
    },
    {
      img: 'image4.png',
      prenomVille: 'Sophie',
      date: 'Septembre 2023',
      temoignage:
        "Cette exploration m'a apporté : \n- des confirmations, validations de traits de caractère que je savais ou qu'on m'avait déjà évoqués \n- des nouveaux concepts : des mots que je ne connaissais pas \n- des échanges enrichissants \n- des liens entre la CNV et d'autres approches  \n- de la confiance : je ne suis pas une extraterrestre  \n- un temps pour moi \n\n J'ai apprécié \n - La mise en lien entre les réponses et les différents facettes de la haute sensibilité. \n - Les partages d'expérience \n - Les explications des différents concepts  \n - le fait de ne pas étiqueter mais de donner de la compréhension sur un mécanisme de réflexion, sur un fonctionnement différent ",
    },
  ]

  return (
    <>
      <section className="sub_container temoignage spaced">
        <div className="text-align-center">
          <h2>Témoignage de personnes que j'ai accompagné</h2>
          <p className="sub_text">
            (Avis Google, extraits de mails, de SMS et de messages sur mes
            réseaux sociaux de personnes que j'ai accompagnées)
          </p>
        </div>
        <div className="temoignage_container">
          {data.map((tem, index) => (
            <div className="temoignage glass" key={index}>
              {tem.img && (
                <img
                  className="illustration_2"
                  src={
                    require(`../../assets/images/temoignage/${tem.img}`).default
                  }
                  alt={`temoignage de ${tem.prenomVille} en ${tem.date}`}
                  width={150}
                  height={150}
                />
              )}

              <div className="content">
                <p
                  dangerouslySetInnerHTML={{
                    __html: tem.temoignage.replace(/\n/g, '<br />'),
                  }}
                ></p>
                <div>
                  <div>{tem.prenomVille}</div>
                  <div>{tem.date}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Link
          to="/explore"
          className="btn_rervervation"
          onClick={() => handleRedirect()}
        >
          VOIR LES OFFRES
        </Link>{' '}
        {/* <a href="https://www.delpfine.com/explore" className="btn_rervervation">
          <p>VOIR LES OFFRES</p>
        </a> */}
      </section>
    </>
  )
}

export default Temoignages
