import './Home.scss'

import React, { useEffect, useRef, useState } from 'react'
// import { useTranslation } from 'react-i18next'
import Newsletter from '../../components/newsletter/Newsletter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faCircleArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import CustomAlert from '../../components/alert/CustomAlert'
import plantImage from '../../assets/images/plantes_bg.avif'
import plongeons from '../../assets/images/plonges_au_plus_profond_de_toi_meme.webp'
import avatarStop from '../../assets/avatar/avatar_stop.png'
import understandYourself from '../../assets/images/understand_yourself.avif'
import understandYourself2 from '../../assets/images/understand_yourself2.avif'
import visio from '../../assets/images/visio1.avif'

function Home() {
  // const { t } = useTranslation()

  const macro_google_id =
    'https://script.google.com/macros/s/AKfycbxFnp_LB3VD2jQmC_g785T_1t9VCg_bcQkA3F1L8v3DR2ZBNf6Yov6KKWaS2XlctnO5/exec'

  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isUserSubscribe, setIsUserSubscribe] = useState(false)

  const [alertConfig, setAlertConfig] = useState({
    open: false,
    severity: '',
    message: '',
    icon: '',
  })

  const tiltRef = useRef(null)

  useEffect(() => {
    const el = tiltRef.current

    if (!el) return

    const height = el.clientHeight
    const width = el.clientWidth

    const handleMove = (e) => {
      const xVal = e.layerX
      const yVal = e.layerY

      const yRotation = 20 * ((xVal - width / 2) / width)
      const xRotation = -20 * ((yVal - height / 2) / height)

      const string = `perspective(500px) scale(1.1) rotateX(${xRotation}deg) rotateY(${yRotation}deg)`
      el.style.transform = string
    }

    const handleMouseOut = () => {
      el.style.transform = 'perspective(500px) scale(1) rotateX(0) rotateY(0)'
    }

    const handleMouseDown = () => {
      el.style.transform = 'perspective(500px) scale(0.9) rotateX(0) rotateY(0)'
    }

    const handleMouseUp = () => {
      el.style.transform = 'perspective(500px) scale(1.1) rotateX(0) rotateY(0)'
    }

    el.addEventListener('mousemove', handleMove)
    el.addEventListener('mouseout', handleMouseOut)
    el.addEventListener('mousedown', handleMouseDown)
    el.addEventListener('mouseup', handleMouseUp)

    return () => {
      el.removeEventListener('mousemove', handleMove)
      el.removeEventListener('mouseout', handleMouseOut)
      el.removeEventListener('mousedown', handleMouseDown)
      el.removeEventListener('mouseup', handleMouseUp)
    }
  }, [])

  const handleRedirect = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  function handleInput(event) {
    setIsEmailValid(true)
    setIsUserSubscribe(false)
    setEmail(event.target.value)
  }

  async function handleSubmit(event) {
    event.preventDefault()

    if (email === '' || !/\S+@\S+\.\S+/.test(email)) {
      setIsEmailValid(false)
      setAlertConfig({
        open: true,
        severity: 'error',
        message: "Cet email n'est pas valide 🙄",
        icon: 'error',
      })
    } else {
      try {
        const response = await fetch(macro_google_id, {
          redirect: 'follow',
          method: 'POST',
          body: JSON.stringify({ email }),
          headers: { 'Content-Type': 'text/plain;charset=utf-8' },
        })
        if (response.ok) {
          setIsUserSubscribe(true)
          setAlertConfig({
            open: true,
            severity: 'success',
            message: 'Merci ! Tu va recevoir par email tous tes documents 😊',
            icon: 'success',
          })
        } else {
          setIsUserSubscribe(false)
          setAlertConfig({
            open: true,
            severity: 'error',
            message: 'Il y a eu une erreur. Merci de tenter à nouveau.',
            icon: 'error',
          })
        }
      } catch (error) {
        setIsUserSubscribe(false)
        setAlertConfig({
          open: true,
          severity: 'error',
          message: 'Il y a eu une erreur. Merci de tenter à nouveau.',
          icon: 'error',
        })
      }
      setIsEmailValid(true)
      setEmail('')
    }
  }

  return (
    <>
      <div className="home_container">
        <div className="home_content">
          <div className="sub_container image_container">
            <div className="gimic_wrapper">
              <div>
                <div>
                  <h2 className="text_bold hero_text">
                    Le coaching sur mesure pour toi<br></br>
                    Hypersensibles (HSP) et Neuro-atypiques (HPI, TSA, DYS,
                    TDAH)
                  </h2>
                  <p className="hero_text">
                    Pour favoriser une belle écologie chez toi !
                  </p>
                </div>
                <p>
                  Découvre comment mon coaching personnalisé peut t’aider à
                  mieux comprendre et réguler ton hypersensibilité ou et ta
                  douance.
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <img src={plantImage} alt="plante" />
            </div>
          </div>
          <section className="sub_container spaced">
            <h2>
              Prêt(e) à découvrir, embrasser ta singularité et à libérer tous
              tes talents ?
            </h2>
            <h3>
              Je suis là pour t'accompagner dans cette incroyable aventure !
            </h3>
          </section>
          <section className="sub_container intro_text">
            <img
              className="illustration_1"
              src={plongeons}
              alt="Plonge au plus profond de toi même"
            />
            <p className="meandre_text">
              Ensemble, plongeons au cœur de ta propre essence, explorons les
              méandres de ton fonctionnement unique.
            </p>
            <div className="grace_a_coaching spaced">
              <p>
                Grâce à mon coaching <strong>personnalisé</strong>
                {', '}
                mes questionnements <strong>ciblés</strong>
                {' et '}
                mes partages <strong>sincères</strong>
              </p>
              <p>
                Tu découvriras tes <strong>forces</strong>, tes{' '}
                <strong>blocages</strong>, et tes <strong>ressources</strong>{' '}
                intérieures insoupçonnées.
              </p>
              <p>
                Ensemble, nous déverrouillerons les portes qui te retiennent,
                pour que tu puisses enfin avancer avec{' '}
                <strong>confiance </strong> et <strong>sérénité</strong>.
              </p>
            </div>{' '}
          </section>

          <section className="sub_container container-sentiments-personnels mb-5">
            <div>
              <p>
                Si tu en as assez de te sentir <strong>en décalage</strong>,{' '}
                <strong>incompris(e)</strong>
              </p>
              <p>
                Ou même de te perdre dans{' '}
                <strong>un sentiment de solitude</strong> malgré une compagnie
                apparente
              </p>
              <p>
                Si tu veux en finir avec ce sentiment d'être en marge de ta
                propre vie, alors c'est le moment de dire..
              </p>{' '}
            </div>
          </section>

          <section className="sub_container container-reprise-en-mains">
            <div>
              <img
                className="img_stop"
                src={avatarStop}
                alt="avatar de delpfine qui fait un signe stop de la main"
              />
              <p>
                C'est le moment de reprendre <strong>les rênes</strong>, de
                retrouver <strong>le sens</strong> de ton existence et de
                dessiner <strong>un avenir</strong> qui te ressemble vraiment !
              </p>

              <p className="text_bold">
                Prêt.e à te lancer dans cette exploration et transformation ?
              </p>
              <p>
                Réserve dès maintenant une séance de 20 minutes avec moi pour
                découvrir comment je peux t'aider à révéler tes talents.
              </p>
              <a
                href="https://zcal.co/delpfine/30min"
                className="btn_rervervation"
              >
                <p>RÉSERVER MA SÉANCE GRATUITE</p>
              </a>
            </div>
            <div className="sub_container free_download">
              <h3>Reçois gratuitement</h3>
              <div className="text_receve_docs">
                <p>
                  La présentation Agile Lyon 2024 "Ce n'est pas du cinéma ! Je
                  suis Neuro Atypique !"
                </p>
                <p>+</p>
                <p> les résultats du test</p>
              </div>
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={handleInput}
                  style={{
                    padding: '10px',
                    fontSize: '16px',
                    border: '2px solid #ddd',
                    borderRadius: '12px',
                  }}
                />
                {/* 
                  <p className="sub_sub_text">
                    * En communicant mon e-mail j'accepte de recevoir vos
                    communications et confirme avoir pris connaissance de votre
                    politique de confidentialité et mentions légales
                  </p> */}
                <button type="submit" className="btn_rervervation">
                  <p>Je veux recevoir mes ressources offertes !</p>
                </button>
              </form>
              {!isEmailValid ? <p>Cet email n'est pas valide 🙄</p> : null}
              {isUserSubscribe ? (
                <p>
                  Merci ! Tu va recevoir les ressources sur ton email dans les
                  minutes qui viennent 😊
                </p>
              ) : null}
            </div>
          </section>

          <section className="sub_container spaced">
            <h2>Qu'est-ce que l'Hypersensibilité et le HPI ?</h2>
            <p>
              L'hypersensibilité se caractérise par une réactivité émotionnelle
              intense, une grande empathie et une sensibilité accrue aux stimuli
              sensoriels. Le HPI, ou Haut Potentiel Intellectuel, se manifeste
              par une curiosité intellectuelle marquée, des capacités de
              réflexion rapide et un sentiment d'être différent des autres.
            </p>
            <div className="standalone_link">
              <Link
                to="/ressources"
                className="cta-link"
                onClick={() => handleRedirect()}
              >
                <FontAwesomeIcon icon={faCircleArrowRight} /> Si tu veux en
                savoir plus, c'est ici !
              </Link>
            </div>
          </section>
          <section className="sub_container spaced">
            <h2>Comment Savoir si tu es Hypersensible ou HPI ?</h2>
            <p>
              Il existe plusieurs signes pour identifier ces traits. Pour en
              savoir plus, tu peux consulter{' '}
              <Link
                to="/ressources"
                className="cta-link"
                onClick={() => handleRedirect()}
              >
                les vidéos ici
              </Link>{' '}
              ou réserver un entretien initial pour discuter de tes expériences
              et déterminer si ces caractéristiques te correspondent.
            </p>
          </section>
          <section className="sub_container spaced">
            <h2>Mon Expertise</h2>
            <p>
              Je te laisse découvrir les témoignages clients pour en savoir plus
              sur ce que tu peux espérer de mon accompagnement qui s'appuie sur
              une formation spécialisée en neuro atypie, en coaching
              professionnel santé et bien être et mon expérience dans
              l'accompagnement de personne hypersensibles et HPI.
            </p>
          </section>
          {/* <section className="sub_container spaced">
            <h2>Ma méthodologie</h2>
            <div>
              <img
                className="illustration_2"
                src="/image/understand_yourself.webp"
                alt="visio conference meeting on a macbook with a cup of tea"
                height={300}
              />
              <p>
                Tu veux connaitre ton fonctionnement pour mieux comprendre ce
                que tu vis ?
              </p>
            </div>
            <div>
              <img
                className="illustration_2"
                src="/image/understand_yourself2.avif"
                alt="visio conference meeting on a macbook with a cup of tea"
                width={300}
              />
              <p>
                Tu veux être accompagné (e) vers une transformation plus
                profonde ?
              </p>
            </div>
          </section> */}
          <section className="sub_container spaced methodologie">
            <h2>Ma méthodologie</h2>
            <div className="cards_methodologie">
              <div className="card-container">
                <div id="card_offre1" className="card">
                  <div className="card-front">
                    <img
                      className="illustration_2"
                      src={understandYourself}
                      alt="Une femme dans un pré regarde son reflet dans un miroir souriante apprend à mieux se comprendre"
                      height={300}
                    />
                    <p>
                      Tu veux connaitre ton fonctionnement pour mieux comprendre
                      ce que tu vis ?
                    </p>
                  </div>
                  <div id="back_offre1_img" className="card-back">
                    <p>
                      <Link
                        to="/explore"
                        className="btn_rervervation"
                        onClick={() => handleRedirect()}
                      >
                        <FontAwesomeIcon icon={faCircleArrowRight} /> Mon offre
                        est ici !
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-container">
                <div id="card_offre2" className="card">
                  <div className="card-front">
                    <img
                      className="illustration_2"
                      src={understandYourself2}
                      alt="visio conference meeting on a macbook with a cup of tea"
                      width={300}
                    />
                    <p>
                      Tu veux être accompagné(e) vers une transformation plus
                      profonde ?
                    </p>
                  </div>
                  <div id="back_offre2_img" className="card-back">
                    <p>
                      <Link
                        to="/suivi"
                        className="btn_rervervation"
                        onClick={() => handleRedirect()}
                      >
                        <FontAwesomeIcon icon={faCircleArrowRight} /> Mon offre
                        est ici !
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sub_container modalites">
            <h2>Modalités</h2>
            <div>
              <img
                className="illustration_2"
                src={visio}
                alt="visio conference meeting on a macbook with a cup of tea"
              />
              <div className="image_hover" id="tilt" ref={tiltRef}>
                <p>
                  Mes accompagnements se font en visio en français dans le monde
                  entier !
                </p>
              </div>
            </div>
          </section>
          <section className="sub_container">
            <Newsletter></Newsletter>
          </section>
          <CustomAlert
            openAlert={alertConfig.open}
            onClose={() => setAlertConfig({ ...alertConfig, open: false })}
            severity={alertConfig.severity}
            icon={alertConfig.icon}
          >
            {alertConfig.message}
          </CustomAlert>
        </div>
      </div>
    </>
  )
}

export default Home
