import './Modal.scss'
import React from 'react'
import { Link } from 'react-router-dom'

const Modal = ({
  show,
  setModalShow,
  confirmAction,
  leaveAction,
  accept,
  refused,
  title,
  description,
  cookieModal,
  modalBg,
}) => {
  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <div
      className={`modalBg  ${show ? 'modal_visible' : 'modal_hidden'}`}
      // onClick={() => {
      //   setModalShow(false)
      //   leaveAction()
      // }}
    >
      <div className={`modal_container  ${cookieModal ? 'cookie_modal' : ''}`}>
        {title && <h3>{title}</h3>}

        <div className="content">
          {description && <p>{description}</p>}

          <Link to="/privacy-policy" onClick={() => backToTop()}>
            {'  '}
            <p>En savoir plus</p>
          </Link>
        </div>

        {(accept || refused) && (
          <div className="btn_actions">
            {accept && (
              <div
                className="modal_button buttonAgree"
                onClick={() => {
                  confirmAction()
                  setModalShow(false)
                }}
              >
                {accept}
              </div>
            )}
            {refused && (
              <div
                className="modal_button buttonDisagree"
                onClick={() => {
                  setModalShow(false)
                  leaveAction()
                }}
              >
                {refused}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Modal
