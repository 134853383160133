import './Accompagnement.scss'
import React, { useState, useEffect } from 'react'

function Accompagnement() {
  const [hoverIndex, setHoverIndex] = useState(null)
  // const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0)
  const [animatedEntries, setAnimatedEntries] = useState(new Set())

  const handleMouseOver = (index) => {
    setHoverIndex(index)
  }

  const handleMouseOut = () => {
    setHoverIndex(null)
  }

  useEffect(() => {
    const animations = ['fade-in'] //, 'fade-in-right', 'fade-in-left']

    const subContainers = document.querySelectorAll('.anim')

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0, // Adjust this value to change when the animation should trigger
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !animatedEntries.has(entry.target)) {
          entry.target.classList.add('show')
          const animationClasses =
            animations[animatedEntries.size % animations.length].split(' ')
          animationClasses.forEach((animationClass) => {
            entry.target.classList.add(animationClass)
          })
          setAnimatedEntries((prev) => new Set(prev).add(entry.target))
        }
      })
    }, options)

    subContainers.forEach((subContainer) => {
      observer.observe(subContainer)
    })

    return () => {
      observer.disconnect()
    }
  }, [animatedEntries])

  return (
    <>
      <div className={`accompagnement_container`}>
        <div className="accompagnement_content">
          <h1>Coaching individuel</h1>

          <div className="sub_container_light anim">
            <h2>LE COACHING C'EST QUOI ?</h2>
            <div className="sub_container_light anim">
              <p>D'abord c'est toi et tes objectifs</p>
              <p>
                Et puis c'est moi qui t'accompagne d'un point A à un point B
                avec mon process, mes outils et mes questions puissantes 
              </p>
              <p>
                Enfin c'est notre partenariat, nous cheminons ensemble pour que
                tu progresses sur ce qui t'empêche aujourd'hui d'avancer tout
                seul. 
              </p>
              <p>
                C'est aussi ton engagement à agir, y aller. C'est toi qui décide
                et moi je m'assure avec toi que tout est sécure.
              </p>
              <p>
                <strong>
                  Et surtout pour que demain tout ça ne soit plus un problème
                  pour toi et que tu sois totalement autonome !
                </strong>
              </p>
            </div>
          </div>
          <div className="sub_container_light anim">
            <h2>TU ES CONCERNÉS SI :</h2>
            <ul id="list" className="list_anim anim">
              {[
                'Tu souhaites changer, transformer quelque chose dans ta vie perso ou pro.',
                'Tu veux optimiser ton potentiel.',
                'Tu veux améliorer ta performance (études, travail, activités créatives…)',
                "Tu recherches des stratégies pour dépasser ton problème de perfectionnisme, sensibilité émotionnelle, d'ennui, l'isolement social, le sentiment de décalage, les problèmes de motivation, de procrastination, ….",
                'Tu veux trouver un équilibre entre des passions diverses.',
                'Tu veux éviter le burn out.',
                'Tu as besoin de ressources et de soutien pour naviguer dans tes situations complexes.',
                'Tu veux trouver un professionnel qui te comprend pour obtenir un soutien adapté.',
              ].map((text, index) => (
                <li
                  key={index}
                  onMouseOver={() => handleMouseOver(index)}
                  onMouseOut={handleMouseOut}
                  className={
                    hoverIndex !== null && hoverIndex !== index
                      ? 'hovering'
                      : ''
                  }
                >
                  <p>{text}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="sub_container_light anim">
            <h2>LE COACH ET SES SPÉCIALISATIONS</h2>
            <div className="sub_container_light anim">
              <p>
                Aujourd'hui la plupart des gens se disent coachs alors qu'ils
                n'ont reçu aucune formation. Il faut savoir que la profession de
                coach est réglementée et pour devenir coach certifié, il est
                nécessaire de valider les étapes suivantes :
              </p>
              <ul id="list" className="list_anim">
                {[
                  "Etre formé par un institut de formation accrédité par l'organisme mondial ICF (c'est un gage de respect international des mêmes règles éthiques, la même charte déontologique et les mêmes compétences).",
                  "Et certifié. Pour la certification, il faut valider 3 audios retranscrits qui respectent l'éthique, la déontologie et les compétences du coach. Un mémoire sur un accompagnement long de 8 à 10 séances avec une même personne, un compte rendu sur un travail personnel sur ses piliers de vie et un QCM réussit à plus de 60% sur les compétences du coach.",
                  'Etre accrédité ICF après 100 heures de coaching minimum.',
                ].map((text, index) => (
                  <li
                    key={index}
                    onMouseOver={() => handleMouseOver(index)}
                    onMouseOut={handleMouseOut}
                    className={
                      hoverIndex !== null && hoverIndex !== index
                        ? 'hovering'
                        : ''
                    }
                  >
                    <p>{text}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="sub_container_light anim">
              <h3>
                ICF internationnal est la première association de coaches dans
                le monde. Ses membres s'engagent à pratiquer un coaching de
                qualité.{' '}
              </h3>
              <div className="sub_container_light anim">
                <p>
                  Les coachs certifiés par l'institut de formation demande
                  ensuite leur certification à ICF. Cette certification est
                  renouvelée tous les 3 ans. Cette certification atteste que le
                  coach a bien été formés aux techniques de coaching, qu'il est
                  reconnu par ses pairs, qu'il continue à développer ses
                  compétences par la formation tout au long de sa carrière et
                  qu'il pratique régulièrement. Le processus de certification
                  est une démarche personnelle. Et peu de coachs s'engagent dans
                  cette démarche car elle est très strict.
                </p>
              </div>
            </div>
          </div>

          <div className="sub_container_light anim">
            <h2>LA FORMATION AU COACHING C'EST :</h2>
            <div className="sub_container_light">
              <ul id="list">
                {[
                  'Un institut de formation international accrédité ICF',
                  'Des profs certifiés et accrédités ICF niveau MCC',
                  'Des apprentissages sur vos problématiques.',
                  "Des heures et des heures d'entraînement quotidiennes pendant des mois",
                  'Pour intégrer un process',
                  'Pour intégrer une posture éthique et déontologique',
                  'Pour intégrer des compétences',
                  'Pour lever nos propres blocages',
                  "D'abord avec les co.coachs pour intégrer",
                  'Puis avec les profs pour valider, confirmer',
                  'Encore avec les co.coachs pour automatiser',
                  'Et avec des coachés extérieurs pour gagner en EXPÉRIENCE.',
                  "C'est une obligation de formation à vie.",
                  'Et des heures de supervisions à vie avec des coachs accrédités.',
                ].map((text, index) => (
                  <li
                    key={index}
                    onMouseOver={() => handleMouseOver(index)}
                    onMouseOut={handleMouseOut}
                    className={
                      hoverIndex !== null && hoverIndex !== index
                        ? 'hovering'
                        : ''
                    }
                  >
                    <p>{text}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="sub_container_light anim">
            <h2>LA CERTIFICATION D'UN COACH C'EST :</h2>
            <div className="sub_container_light">
              <ul id="list">
                {[
                  'Des soirées de pratique avec nos superviseurs où on expérimente chacun des 3 rôles coach, coaché et observateur.',
                  '2 journées complètes de pratique',
                  "3 audios + tapuscrit validé par l'organisme d'accréditation s'ils respectent le process, les compétences et le code éthique et déonthologique.",
                  "Un mémoire de 8 séances d'accompagnement d'un coaché extérieur à l'organisme de formation.",
                  'Un rapport sur notre travail personnel pour nous ancrer, nous aligner vers cette posture du coach.',
                ].map((text, index) => (
                  <li
                    key={index}
                    onMouseOver={() => handleMouseOver(index)}
                    onMouseOut={handleMouseOut}
                    className={
                      hoverIndex !== null && hoverIndex !== index
                        ? 'hovering'
                        : ''
                    }
                  >
                    <p>{text}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="sub_container_light anim">
            <h2>DONC ETRE COACH C'EST QUOI ?</h2>
            <div className="sub_container_light anim">
              <p>Tu l'as bien compris, ça ne s'improvise pas ! </p>
              <p>C'est une pratique réglementée ! </p>
              <p>Qui respecte un code étique et déontologique ! </p>
              <p> Le coach maitrise un processus expert ! </p>
              <p>Avec des questions puissantes ! </p>
              <p> Et des compétences reconnues mondialement ! </p>
              <p>Par l'organisme international ICF !</p>
            </div>
          </div>

          <div className="sub_container_light anim">
            <h2>L'ASSURANCE D'UN BON COACHING C'EST :</h2>
            <div className="sub_container_light anim">
              <p>
                Un diplôme qui atteste que le coach est bien certifié par un
                institut international de coaching qui lui-même est accrédité
                ICF. C'est la garantie que ton coach :
              </p>
              <ul id="list">
                {[
                  'Est passé par tout cette validation',
                  "Qu'il travaille sur lui",
                  "Qu'il se forme constamment",
                  "Et qu'il est supervisé par des mentors accrédités ICF",
                ].map((text, index) => (
                  <li
                    key={index}
                    onMouseOver={() => handleMouseOver(index)}
                    onMouseOut={handleMouseOut}
                    className={
                      hoverIndex !== null && hoverIndex !== index
                        ? 'hovering'
                        : ''
                    }
                  >
                    <p>{text}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="sub_container_light anim">
            <h2>COMMENT SE DÉROULE UNE SÉANCE DE COACHING ?</h2>
            <div className="sub_container_light anim">
              <h3>2 formules :</h3>
              <div className="formules_container">
                <div className="sub_container_light anim">
                  <h4>LE COACHING PUR : (TÊTE ET COEUR)</h4>
                  <ul id="list">
                    {[
                      "<strong>Un premier RDV gratuit de 20 minutes</strong> au cours duquel nous ferons connaissance et nous verrons à partir de tes problématiques quel type d'accompagnement est préférable pour toi.",
                      "<strong>Une première séance d'1h</strong> pendant laquelle nous fixerons tes objectifs et tes indicateurs de réussite.",
                      'Puis <strong>des séances de 45 minutes de coaching pur</strong> où tu amènera systématiquement une situation à travailler en lien avec tes objectifs.',
                      'Tous les 15 jours ou toutes les semaines <strong>en fonction de tes besoins</strong> et de mes disponibilités.',
                      "Et un incontournable, tu mettras en œuvre entre les séances <strong>le plan d'action que tu auras fait émerger en séance</strong>.",
                    ].map((text, index) => (
                      <li
                        key={index}
                        onMouseOver={() => handleMouseOver(index)}
                        onMouseOut={handleMouseOut}
                        className={
                          hoverIndex !== null && hoverIndex !== index
                            ? 'mb-2 hovering'
                            : 'mb-2'
                        }
                      >
                        <p dangerouslySetInnerHTML={{ __html: text }}></p>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="sub_container_light anim">
                  <h4>LE COACHING BIEN ÊTRE : (TÊTE, CŒUR, CORPS)</h4>
                  <ul id="list">
                    {[
                      "<strong>Un premier RDV gratuit de 20 minutes</strong> au cours duquel nous ferons connaissance et nous verrons à partir de tes problématiques quel type d'accompagnement est préférable pour toi.",
                      "<strong>Une première séance d'1h</strong> pendant laquelle nous fixerons tes objectifs et tes indicateurs de réussite.",
                      "Puis <strong>des séances de 1h30 minutes de coaching pur</strong>, d'apport de connaissances sur ta neuroatypie et de mise en place d'outils pour apprendre à réguler ton système sensoriel et émotionnel.",
                      'Tous les 15 jours ou toutes les semaines <strong>en fonction de tes besoins</strong> et de mes disponibilités.',
                      "Et un incontournable, tu mettras en œuvre entre les séances <strong>le plan d'action que tu auras fait émerger en séance et des outils que je t'aurai partagés</strong>.",
                    ].map((text, index) => (
                      <li
                        key={index}
                        onMouseOver={() => handleMouseOver(index)}
                        onMouseOut={handleMouseOut}
                        className={
                          hoverIndex !== null && hoverIndex !== index
                            ? 'mb-2 hovering'
                            : 'mb-2'
                        }
                      >
                        <p dangerouslySetInnerHTML={{ __html: text }}></p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sub_container_light anim">
            <h2>LES CONDITIONS POUR RÉUSSIR TON COACHING</h2>
            <div className="sub_container_light anim">
              <ul id="list">
                {[
                  'Tenir et respecter le contrat',
                  'Un espace de confiance réciproque,',
                  'Un espace de sécurité',
                  'La garantie du secret professionnel',
                  'Se sentir libre de dire ou de ne pas dire',
                  'La garantie du secret professionnel',
                  'Le non jugement',
                  'La ponctualité',
                  'La régularité',
                  'Honorer ses séances',
                  'Une pleine présence et écoute',
                  "Accepter d'être challenger",
                  'Arriver avec un sujet',
                  "Passer à l'action",
                ].map((text, index) => (
                  <li
                    key={index}
                    onMouseOver={() => handleMouseOver(index)}
                    onMouseOut={handleMouseOut}
                    className={
                      hoverIndex !== null && hoverIndex !== index
                        ? 'hovering'
                        : ''
                    }
                  >
                    <p>{text}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="sub_container container-reprise-en-mains anim">
            <div>
              <p>Prêt(e) à te lancer dans ta transformation ?</p>
              <p>
                Réserve dès maintenant une séance de 20 minutes avec moi pour
                découvrir comment je peux t'aider à révéler tes talents.
              </p>
              <a
                href="https://zcal.co/delpfine/30min"
                className="btn_rervervation"
              >
                <p>RÉSERVER MA SÉANCE GRATUITE</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Accompagnement
