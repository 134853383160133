import './QuestionsAsk.scss'
import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion'
// import AccordionActions from '@mui/material/AccordionActions'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faCircleArrowRight,
  faRemove,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
// import Button from '@mui/material/Button'

function QuestionsAsk({ asCTA }) {
  const [searchTerm, setSearchTerm] = useState('')

  const handleRedirect = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  let data = [
    {
      id: 1,
      question: 'Comment savoir si je suis hypersensible ou HPI ?',
      reponse:
        'Nous proposons un entretien initial pour discuter de tes expériences et déterminer si ces caractéristiques te correspondent.',
    },
    {
      id: 2,
      question:
        "Quels sont les bénéfices du coaching pour quelqu'un comme moi ?",
      reponse:
        'Tu peux espérer une meilleure régulation des émotions,\n une optimisation de tes compétences personnelles et professionnelles, \n et un renforcement de la confiance en toi.',
      standaloneLabel: 'Tu auras plus de détail ici.',
      standaloneLink: 'http://localhost:3000/explore',
    },
    {
      id: 3,
      question:
        'Quelle est votre expérience avec des clients hypersensibles ou HPI ?',
      reponse:
        "J'ai aidé de nombreux clients à mieux comprendre et réguler leur hypersensibilité et leur HPI grâce à des techniques adaptées et une approche personnalisée.",
      standaloneLabel: 'Tu peux aller voir les témoignages ici.',
      standaloneLink: 'http://localhost:3000/explore',
    },
    {
      id: 4,
      question: 'Combien de temps dure chaque séance de coaching ?',
      reponse:
        'Chaque séance de coaching pur dure généralement entre 45 minutes et 90 minutes en fonction de tes besoins et de tes objectifs.',
    },
    {
      id: 5,
      question:
        'Est-ce que le coaching est adapté pour les enfants ou adolescents hypersensibles ou HPI ?',
      reponse:
        "Pas pour le moment, c'est en préparation ! Je proposerai dans quelque temps des sessions spécifiquement adaptées pour les adolescents, avec des approches et des outils appropriés à leur âge et à leur développement.",
    },
    {
      id: 6,
      question: 'Comment mesurez-vous les progrès durant le coaching ?',
      reponse:
        "Les progrès sont mesurés par des objectifs et indicateurs définis en début d'accompagnement de coaching, des retours réguliers lors des séances de tes avancées et de tes réussites, un bilan de mi-parcours et un bilan de fin de coaching.",
    },
    {
      id: 7,
      question:
        'Le coaching peut-il aider à améliorer mes relations interpersonnelles ?',
      reponse:
        "Absolument. Je travaille souvent sur des compétences telles que la communication, l'empathie, et la régulation des émotions, qui sont essentielles pour améliorer les relations personnelles et professionnelles.",
    },
    {
      id: 8,
      question: 'Quels sont les coûts associés au coaching ?',
      reponse:
        "Les tarifs varient en fonction du type de coaching et de la durée des séances. J'offre un premier entretien gratuit pour discuter de tes besoins et te proposer un devis personnalisé.",
    },
    {
      id: 9,
      question: 'Offrez-vous des sessions de coaching en ligne ?',
      reponse:
        'Oui, toutes mes sessions de coaching sont en ligne via une plateforme sécurisée.',
    },
    {
      id: 10,
      question: 'Combien de séances sont nécessaires pour voir des résultats ?',
      reponse:
        'Le nombre de séances varie selon les individus et leurs objectifs. En général, des progrès significatifs peuvent être observés après 6 à 8 séances.',
    },
    {
      id: 11,
      question: 'Combien de temps entre les séances ?',
      reponse:
        'Les séances ont généralement lieu tous les 15 jours. Selon les besoins et les objectifs, nous pouvons décider de les rapprocher ou de les espacer. ',
    },
    {
      id: 12,
      question:
        'Quelle est la différence entre un psychologue et un coach pour les hypersensibles / HPI ?',
      reponse:
        "Un psychologue se concentre souvent sur le diagnostic et le traitement des troubles mentaux, tandis qu'un coach se concentre sur le développement personnel et l'atteinte d'objectifs spécifiques dans le présent et l'avenir. Les deux peuvent être complémentaires selon tes besoins. Je n'hésite pas à te réorienter si je le considère nécessaire.",
    },
    {
      id: 13,
      question:
        'Quelle est la différence entre un conseiller, consultant et un coach ? ',
      reponse:
        "Le coach aide le client à trouver ses propres solutions. Le consultant fournit des solutions spécifiques et des plans d'action basés sur son expertise. Le conseiller donne des recommandations et des conseils pour aider à la prise de décision. Pendant les séances de coaching pur, je garde ma casquette de coach. Mais j'ai les connaissances pour analyser, évaluer, recommander et donner des conseils à certains moments si je le juge nécessaire ou dans la deuxième partie si tu choisis l'offre plus ambitieuse.",
      standaloneLabel: "Je t'en dis plus ici.",
      standaloneLink: 'http://localhost:3000/explore',
    },
    {
      id: 14,
      question: 'Quelle est la différence en un formateur et un coach ?',
      reponse: 'Le formateur fait un apport de connaissance.',
      standaloneLabel: "C'est ce que je fais ici.",
      standaloneLink: 'http://localhost:3000/explore',
    },
    {
      id: 15,
      question: 'Le coaching est-il confidentiel ?',
      reponse:
        'Oui, toutes les informations partagées durant les séances de coaching sont strictement confidentielles et protégées par notre charte éthique ICF.',
    },
    {
      id: 16,
      question: 'Puis-je arrêter le coaching si je ne suis pas satisfait ?',
      reponse:
        'Oui, tu peux arrêter le coaching à tout moment. Nous prévoirons une dernière séance pour faire le bilan. Je souhaite que tu sois entièrement satisfait de notre collaboration.',
    },
    {
      id: 17,
      question: 'Le coaching est-il couvert par les assurances ?',
      reponse: "Non le coaching n'est pas couvert par les assurances.",
    },
    {
      id: 18,
      question: 'Avez-vous des témoignages de clients précédents ?',
      reponse:
        "Oui, tu peux consulter des témoignages de clients précédents sur ma page dédiée. Ils partagent leurs expériences et les bénéfices qu'ils ont retirés de notre coaching.",
      standaloneLink: 'http://localhost:3000/explore',
    },
    {
      id: 19,
      question: 'Comment puis-je savoir si le coaching est fait pour moi ?',
      reponse:
        "Le coaching convient à ceux qui sont prêts à s'investir dans leur développement personnel et à travailler sur eux-mêmes. Ce qui est essentiel, c'est le passage à l'action. Un entretien initial gratuit peut t'aider à déterminer si le coaching répond à tes besoins spécifiques.",
    },
    {
      id: 20,
      question:
        'Quelle est la différence entre un coach spécialisé en Hyper sensibilité et HPI et un coach généraliste ?',
      reponse:
        "Un coach spécialisé en HPI comprend les défis uniques et les atouts des personnes hyper sensibles à haut potentiel intellectuel, et utilise des techniques adaptées pour les aider à s'épanouir pleinement.",
    },
    {
      id: 21,
      question: "Puis-je faire appel à un coach en complément d'une thérapie ?",
      reponse:
        "Oui, le coaching peut être complémentaire à une thérapie. Alors que la thérapie se concentre souvent sur le traitement de problèmes psychologiques, le coaching se concentre sur l'atteinte d'objectifs spécifiques et le développement personnel. C'est davantage tourné vers le présent et l'avenir.",
    },
    {
      id: 22,
      question: 'Quels sont les critères pour choisir un bon coach ?',
      reponse:
        'Il est important de vérifier les qualifications et les certifications du coach, ainsi que son expérience avec des clients ayant des profils similaires au tien. Des recommandations et des témoignages peuvent également être utiles.',
      standaloneLabel: 'Je réponds plus en détail à cette question ici',
      standaloneLink: 'http://localhost:3000/suivi',
    },
    {
      id: 23,
      question:
        "Comment le coaching peut-il m'aider dans ma vie professionnelle ?",
      reponse:
        "Le coaching peut t'aider à identifier tes forces et tes faiblesses, à développer des compétences de gestion du stress et à améliorer ta communication et ton efficacité au travail.",
    },
    {
      id: 24,
      question:
        'Est-ce que le coaching en hypersensibilité et HPI inclut des tests de diagnostic ?',
      reponse:
        "Non, le coaching ne comprend pas de tests diagnostiques du type WAIS OU WISC. Cependant, je suis formée à l'exploration de douance qui selon moi t'apportera davantage qu'une note. Tu auras une connaissance très approfondie de ton fonctionnement.",
      standaloneLabel: "Je t'en dis plus ici.",
      standaloneLink: 'http://localhost:3000/suivi',
    },
    {
      id: 25,
      question:
        'Quels sont les outils spécifiques utilisés pour les personnes HPI ?',
      reponse: '',
      standaloneLabel: 'Je t’invite à découvrir ma boite à outils ici.',
      standaloneLink: 'http://localhost:3000/suivi',
    },
    {
      id: 26,
      question:
        "Puis-je bénéficier du coaching même si je ne suis pas sûr d'être HPI ?",
      reponse:
        "Oui, le coaching peut être bénéfique même si tu n'es pas certain d'être HPI. Je me concentre sur tes objectifs personnels et sur les défis que tu souhaites surmonter.",
    },
    {
      id: 27,
      question: 'Comment puis-je maximiser les bénéfices de mon coaching ?',
      reponse:
        "Pour tirer le meilleur parti de ton coaching, il est important d'être ouvert, honnête et engagé dans le processus. Faire des exercices entre les séances, appliquer les stratégies discutées en séance en mettant en place ton plan d’action aidera également à maximiser les résultats.",
      standaloneLabel: 'Tu auras plus de détails ici',
      standaloneLink: 'http://localhost:3000/suivi',
    },
    {
      id: 28,
      question: 'Quelles sont les qualifications du coach ?',
      reponse:
        "Je suis certifiée coach professionnelle par l'institut de coaching internationale de Genève qui est accrédité par l'ICF (International Coach Federation) et possède une formation spécialisée en neuroatypie, santé bien être et CLEEN, ainsi qu'une expérience significative dans l'accompagnement de personnes hypersensibles et HPI.",
    },
    {
      id: 29,
      question:
        "Quelle est la durée minimale d'engagement pour un programme de coaching ?",
      reponse:
        "La durée minimale d'engagement dépend de tes objectifs et de tes besoins spécifiques. Cependant, un engagement initial de 6 séances est souvent recommandé pour observer des progrès significatifs.",
      standaloneLabel: "Tu peux avoir plus d'information ici.",
      standaloneLink: 'http://localhost:3000/suivi',
    },
    {
      id: 30,
      question: 'Comment puis-je préparer ma première séance de coaching ?',
      reponse:
        'Pour préparer ta première séance, réfléchis à tes objectifs, aux défis que tu rencontres, et à ce que tu espères tirer du coaching. Sois prêt à discuter ouvertement de ces aspects avec ton coach.',
    },
    {
      id: 31,
      question:
        "Offrez-vous des ateliers ou des séminaires sur l'hypersensibilité et le HPI ?",
      reponse:
        "Non, pas pour le moment. Mais j'y pense. Si les demandes se multiplient, je pourrais mettre en place des ateliers de groupe pour approfondir des sujets spécifiques et offrir des opportunités de développement personnel en groupe.",
    },
    {
      id: 32,
      question: 'Comment puis-je rester motivé entre les séances de coaching ?',
      reponse:
        'Je travaille sur ta motivation et ton engagement pour que tu restes engagé et motivé entre les séances. De plus, un suivi régulier par email ou message peut être organisé pour soutenir ta progression.',
    },
  ]

  const keywordMapping = {
    prix: ['prix', 'cout', 'achat', 'coûts', 'coût', 'offre'],
  }

  // Fonction pour récupérer tous les mots-clés et leurs synonymes
  const getAllKeywords = (term) => {
    const terms = term.toLowerCase().split(' ')
    let keywords = []

    terms.forEach((word) => {
      if (keywordMapping[word]) {
        keywords.push(word)
        keywords = [...keywords, ...keywordMapping[word]]
      } else {
        keywords.push(word)
      }
    })

    return keywords
  }

  const filteredData = data.filter((item) => {
    const searchWords = getAllKeywords(searchTerm)
    return searchWords.some(
      (word) =>
        item.question.toLowerCase().includes(word) ||
        item.reponse.toLowerCase().includes(word)
    )
  })

  const displayedData = asCTA ? filteredData.slice(0, 3) : filteredData

  return (
    <>
      <section className="sub_container spaced questionsAsk_container">
        <div className="mb-2 text-align-center">
          <h2>Questions fréquentes</h2>
          <p className="sub_text">Toutes les réponses à vos questions</p>
        </div>

        <div className="questionsAsk_contener">
          {!asCTA && (
            <>
              <div className="search_bar">
                <input
                  type="text"
                  placeholder="Combien de temps entre les séances ?..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {searchTerm.length > 0 && (
                  <FontAwesomeIcon
                    className="remove"
                    icon={faRemove}
                    onClick={(e) => setSearchTerm('')}
                  />
                )}
              </div>
              {filteredData.length > 0 && (
                <p className="m-1 sub_text">{`${filteredData.length} Réponse(s) trouvée(s) 😎`}</p>
              )}
              <span></span>
              {filteredData.length === 0 && (
                <p className="m-1 sub_text">Pas de réponse trouvée 🙄</p>
              )}
            </>
          )}
          {displayedData.map((item, index) => (
            <Accordion key={index} defaultExpanded={index === 0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                {item.question}
              </AccordionSummary>
              <AccordionDetails>
                {item.reponse.split('\n').map((line, idx) => (
                  <p key={idx}>{line}</p>
                ))}
                {item.standaloneLink && (
                  <div className="standalone_link">
                    <a href={item.standaloneLink}>
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                      {item.standaloneLabel || "plus d'information ici"}
                    </a>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
          {asCTA && (
            <div className="m-2 text-align-center">
              <Link
                to="/faq"
                className="cta-link"
                onClick={() => handleRedirect()}
              >
                <FontAwesomeIcon icon={faCircleArrowRight} /> ACCEDER À LA FAQ
              </Link>
            </div>
          )}
          <div className="sub_container_light">
            <p>
              Pour plus de questions, n'hésite pas à me contacter directement.
              Je serai ravis de répondre à toutes tes interrogations !
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default QuestionsAsk
