import './Explore.scss'
import React from 'react'
import medal from '../../assets/icons/medal.png'
import philosopher from '../../assets/icons/philosopher.png'
import phinkGreen from '../../assets/icons/think-green.png'
import differentiation from '../../assets/icons/differentiation.png'
import intellectualProperty from '../../assets/icons/intellectual-property.png'
import openBook from '../../assets/icons/open-book.png'

function Explore() {
  const icons = [
    {
      src: medal,
      alt: 'medal',
    },
    {
      src: philosopher,
      alt: 'philosopher',
    },
    {
      src: phinkGreen,
      alt: 'think-green',
    },
    {
      src: differentiation,
      alt: 'differentiation',
    },
    {
      src: intellectualProperty,
      alt: 'intellectual-property',
    },
    {
      src: openBook,
      alt: 'open-book',
    },
  ]
  return (
    <>
      <div className={`explorer_son_fonctionnement_container`}>
        <div className={`explorer_son_fonctionnement_content`}>
          <h1>Explorer son fonctionnement de douance</h1>
          <div className="sub_container_light justify">
            <h2>L'exploration de douance</h2>
            <p>
              L'exploration de son fonctionnement est issue des travaux du
              docteur Marie Rocamora spécialiste du HPI, puis a évolué avec les
              travaux des docteurs en psychologie Mary-Elaine Jacobsen, Deirdre,
              Lovecky et Linda Kreger Silverman aux USA. Elle consiste en un
              entretien structuré par des questions ouvertes. C'est une analyse
              de qui tu es. Comme une investigation qui va te permettre de
              connaître ton fonctionnement pour découvrir comment se manifeste
              tes particularités, ta singularité. Cette exploration nécessite
              les connaissances d'un expert formé au HPI et à
              l'hypersensibilité. Car il n'existe pas de profil type.
            </p>
          </div>
          <div className="sub_container_light justify">
            <p>
              Il n’y a pas de score comme pour le WAIS ou le WISC. C’est un
              échange, une discussion entre toi et moi. Dans laquelle je vais
              prendre en considération tout ce que tu me dis, ou ne me dis pas,
              le paraverbal, l’émotionnel ….
            </p>
          </div>
          <div className="sub_container_light justify">
            <p>
              Ce test permet d’établir une cartographie très détaillée de tous
              tes fonctionnements.
            </p>
          </div>
          <div className="sub_container_light justify">
            <p>
              Ce fonctionnement que tu vis actuellement comme un fardeau, un
              poids, un décalage, un mal être, simplement par méconnaissance.
            </p>
          </div>
          <div className="sub_container_light justify">
            <p>
              Connaitre ton fonctionnement te permettra de comprendre toute ton
              histoire passée que tu vas te repasser avec tes nouvelles
              lunettes, d'accueillir qui tu es maintenant, puis de t’accepter,
              de t'apprécier, et enfin de vivre avec qui tu es et non pas avec
              qui ou devrais être.
            </p>
          </div>
          <div className="sub_container_light justify">
            <p>
              Passer ce test ne changera pas qui tu es mais au contraire, il va
              te permettre de mieux te connaitre, de découvrir quelles sont tes
              forces et tes faiblesses.
            </p>
          </div>
          <div className="sub_container_light">
            <h2>A qui s'adresse cette exploration de douance ?</h2>
            <p className="sub_text">
              Si tu t'intéresses à mon site, c'est que tu es très certainement
              concerné(e) !
            </p>
          </div>
          <div className="sub_container_light grey-bg">
            <h3>Cette exploration est pour toi si :</h3>
            <div className="sub_container_light">
              <ul className="design-list">
                {[
                  "Tu as <strong>besoin d'un professionnel</strong> qui te comprend !",
                  'Tu veux <strong>enfin comprendre clairement</strong> comment tu fonctionnes !',
                  'Tu veux <strong>te comprendre pour améliorer</strong> tes capacités !',
                  'Tu veux <strong>donner du sens</strong> à tes expériences personnelles uniques et à ce sentiment de décalage !',
                  'Tu veux <strong>lever tes doutes</strong> sur ton haut potentiel intellectuel et ton hypersensibilité !',
                  "Tu veux <strong>t'offrir une nouvelle page dans ta vie</strong> plus conforme à qui tu es et plus épanouissante !",
                ].map((text, index) => (
                  <li key={index}>
                    {icons[index] && (
                      <img
                        src={`${icons[index].src}`}
                        alt={`${icons[index].alt}`}
                        width={50}
                        height={50}
                      />
                    )}
                    <p dangerouslySetInnerHTML={{ __html: text }}></p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="sub_container_light justify">
            <p>
              Cette exploration est un chemin enrichissant et transformateur qui
              te permet de mieux comprendre et d’exploiter tes capacités
              uniques, de surmonter tes défis spécifiques et de t’épanouir
              pleinement dans ta vie personnelle et professionnelle.
            </p>
          </div>
          <div className="sub_container_light">
            <h2>Comment se déroule cette exploration ?</h2>
            <div className="sub_container_light justify">
              <p>
                Tu réserves ton appel gratuit et nous échangerons en visio
                autour de ce qui te motive et ton projet.
              </p>
              <p>
                Je t’expliquerai à ce moment là plus en détail les modalités.
              </p>
              <p>Si nous nous entendons pour poursuivre, dans ce cas,</p>
              <p>
                Nous prendrons un RDV en visio pour l’exploration. Il faudra
                compter 2h30. il te faut absolument un endroit calme et être
                seul(e). Ce test est très personnel et intime.
              </p>
              <p>
                Au cours de ce RDV, je vais te poser des questions pour
                lesquelles tu es tout à fait libre d’y répondre. Il n’y a pas de
                réponse attendues, toutes les réponses sont acceptables.
              </p>
              <p>
                A partir de tout ce que j’aurai observé et noté. J’ai
                personnellement un travail d’analyse entre 5 à 10h.
              </p>
              <p>
                Puis, nous fixerons un RDV en visio, toujours au calme pour la
                restitution de mon analyse. Au cours de ce RDV je te
                communiquerai oralement et par écrit l’analyse de ton
                fonctionnement et les points sur lesquels tu peux avoir besoin
                de travailler.
              </p>
              <p>
                Deux mois plus tard, je te recontacterai pour savoir où tu en
                es.
              </p>
              <p>
                <strong>
                  Tu auras ainsi des clés de compréhension sur ton
                  fonctionnement cognitif et émotionnel.
                </strong>
              </p>
            </div>
          </div>
          <div className="sub_container_light">
            <h2>Deux formules :</h2>

            <div className="formules">
              <div className="sub_container_light formule glass">
                <div>
                  <h3>1ère formule</h3>
                  <ul>
                    <li>
                      <p>RDV gratuit</p>
                    </li>
                    <li>
                      <p>Exploration de ton fonctionnement</p>
                    </li>
                    <li>
                      <p>Restitution</p>
                    </li>
                    <li>
                      <p>Appel 2 mois plus tard sur RDV.</p>
                    </li>
                  </ul>
                </div>
                <a
                  href="https://zcal.co/delpfine/30min"
                  className="btn_rervervation"
                >
                  <p>Bénéficier du programme</p>
                </a>
              </div>
              <div className="sub_container_light formule glass">
                <div className="pastille">
                  <p>POUR LES PLUS AMBITIEUX</p>
                </div>
                <div>
                  <h3>2ème formule</h3>
                  <ul>
                    <li>
                      <p>RDV gratuit</p>
                    </li>
                    <li>
                      <p>Exploration de ton fonctionnement</p>
                    </li>
                    <li>
                      <p>Restitution</p>
                    </li>
                    <li>
                      <p>Appel 2 mois plus tard sur RDV.</p>
                    </li>
                    <li>
                      <p>
                        je suis joignable par écrit sur whatsApp pour toutes
                        questions, conseils pendant les deux mois qui suivent
                        l'exploration. Je réponds par écrit dans les 48h.
                      </p>
                    </li>
                  </ul>
                </div>
                <a
                  href="https://zcal.co/delpfine/30min"
                  className="btn_rervervation"
                >
                  <p>Bénéficier du programme</p>
                </a>
              </div>
            </div>
          </div>

          <div className="sub_container_light conseil">
            <h4>Un petit conseil !</h4>
            <p>
              Tu es libre de garder cette exploration pour toi dans un premier
              temps 😄
            </p>
          </div>

          <div className="sub_container_light">
            <h2>En résumé :</h2>
            <ul>
              <li>
                <p>
                  Tu n'es pas obligé de faire cette exploration, surtout si tout
                  va très bien pour toi !
                </p>
              </li>
              <li>
                <p>
                  Un professionnel qualifié et formé est préférable pour éviter
                  les confusions.
                </p>
              </li>
              <li>
                <p>
                  Tu peux comprendre ton fonctionnement de différentes façons.
                  Ici, moi je te propose cette exploration.
                </p>
              </li>
              <li>
                <p>
                  La neuro atypie est très colorée, il n'y a pas un seul profil
                  type comme on voit dans les médias. Et l'intensité de ce que
                  vit la personne est très variable.
                </p>
              </li>
              <li>
                <p>
                  Cette exploration est reconnue internationalement mais pas en
                  France. Quel dommage ! Car le WISK et le WAIS ne sont pas
                  adaptés à tous les profils.
                </p>
              </li>
              <li>
                <p>Cette exploration ne remplace pas l'avis d'un médecin</p>
              </li>
            </ul>
          </div>
          <div className="sub_container_light text-align-center">
            <div className="sub_container_light">
              <p>
                Prêt(e) à te lancer dans cette exploration et à découvrir tes
                couleurs !
              </p>
              <p>
                La réservation de ton RDV gratuit de 20 minutes se fait ici !
              </p>
            </div>
            <a
              href="https://zcal.co/delpfine/30min"
              className="btn_rervervation"
            >
              <p>RÉSERVER MA SÉANCE GRATUITE</p>
            </a>
          </div>
          <div className="sub_container_light"></div>
        </div>
      </div>
    </>
  )
}

export default Explore
