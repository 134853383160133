import './APropos.scss'

import React from 'react'
import avatarCoucou from '../../assets/avatar/avatar_coucou_no_text.png'
import avatarDisco from '../../assets/avatar/avatar_disco.png'
import avatarChampion from '../../assets/avatar/avatar_champion.png'
import suivi from '../../assets/images/suivi.webp'
import hpi from '../../assets/images/hpi.webp'
import planteWithEye from '../../assets/images/plante_with_eye.webp'

function APropos() {
  return (
    <div className="aPropos_container">
      <h1>A propos</h1>

      <h2>
        Discernement engagement loyauté Profondeur finesse intérêt naturel
        empathie
      </h2>
      <div className="aPropos_content">
        <div className="sub_container_light presentation justify">
          <img
            className="illustration_1"
            src={avatarCoucou}
            alt={`avatar de delpfine qui salut de la main`}
            height={250}
          />
          <div class="sub_container_light">
            <h3>Bonjour, moi, je suis Delpfine</h3>
            <p>
              Coach professionnelle certifiée par l'institut de coaching
              international de Genève accrédité ICF. Je suis spécialisée dans
              l'hypersensibilité, la neuro atypie, la santé et le bien être.
            </p>
            <p>Je suis très orientée solution et action.</p>
          </div>
        </div>
        <div className="sub_container_light presentation justify">
          <div class="sub_container_light">
            <p>
              Je mets mes 20 ans d'expérience et mon engagement en tant
              qu'enseignante, formatrice d'enseignants, coach, médiatrice CNV,
              autrice d'une thèse sur la confiance en soi, au service des neuro
              atypiques c'est à dire les personnes :
            </p>
            <ul className="list">
              <li>
                <p>Hypersensibles, </p>
              </li>
              <li>
                <p>À hauts potentiels intellectuels, </p>
              </li>
              <li>
                <p>Multipotientiels </p>
              </li>
              <li>
                <p>Avec des troubles du spectre autistique et/ ou </p>
              </li>
              <li>
                <p>Des troubles de l'attention et de l'hyperactivité. </p>
              </li>
            </ul>
          </div>
          <img
            className="illustration_1"
            src={avatarDisco}
            alt={`avatar de delpfine qui danse le disco`}
            height={250}
          />
        </div>
        <div class="sub_container_light">
          <p className="text_bold text-align-center">
            J'œuvre pour que l'hypersensibilité soit reconnue comme un super
            pouvoir !
          </p>
          <div className="presentation_img_container">
            <img
              src={avatarChampion}
              alt={`avatar de delpfine champion`}
              width={350}
              height={350}
            />
          </div>
        </div>
        <div>
          <div id="cards_a_propos" className="cards_methodologie">
            <div className="card-container">
              <div id="card_accomp1" className="card">
                <div className="card-front">
                  <img
                    className="illustration_2"
                    src={suivi}
                    alt="visio conference meeting on a macbook with a cup of tea"
                    height={300}
                  />
                  <h3>JE T'ACCOMPAGNE POUR :</h3>
                </div>
                <div className="card-back">
                  <p>
                    Je t'accompagne pour mieux te comprendre, trouver et prendre
                    ta place, gérer les pressions, réguler ton stress et tes
                    émotions, gagner en confiance, en acceptation, en
                    affirmation, en estime, en amour de soi, fixer des limites,
                    lutter contre la procrastination, le perfectionnisme, le
                    syndrome de l'imposteur, s'épanouir dans tes relations….
                  </p>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div id="card_accomp2" className="card">
                <div className="card-front">
                  <img
                    className="illustration_2"
                    src={hpi}
                    alt="visio conference meeting on a macbook with a cup of tea"
                    width={300}
                  />
                  <h3>
                    MOI-MÊME HPI, HYPERSENSIBLE, MULTI PASSIONNÉE ET MULTI
                    POTENTIEL :
                  </h3>
                </div>
                <div className="card-back">
                  <p>
                    Je sais et je connais ce que tu traverses pour l'avoir vécu
                    moi-même. Et pour l'avoir accompagné chez mes élèves de tout
                    âge dans tous les niveaux de classe de la maternelle au
                    collège. J'ai fait et je poursuis ce travail
                    d'introspection, de prise de hauteur, d'analyse, de prise de
                    conscience qui me permet aujourd'hui, d'être ancrée, alignée
                    avec mes besoins, mes valeurs. J'ai renforcé mes piliers qui
                    sont maintenant solides. En bref, je connais parfaitement
                    mes couleurs et je sais ce qui est bon pour moi, ce qui ne
                    l'ai pas. Je m'aime, me respecte et suis en capacité de
                    poser mes limites pour moi et pour les autres. Je vis
                    pleinement l'instant présent et je me projette vers un
                    avenir que je construis en adéquation à mes couleurs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="cards_a_propos2" className="cards_methodologie">
            <div className="card-container">
              <div id="card_accomp1" className="card">
                <div className="card-front">
                  <img
                    className="illustration_2"
                    src={planteWithEye}
                    alt="visio conference meeting on a macbook with a cup of tea"
                    height={300}
                  />
                  <h3>MA CONTRIBUTION AUJOURD'HUI C'EST :</h3>
                </div>
                <div className="card-back text-align-center">
                  <p>
                    De t'aider à découvrir la palette unique de tes couleurs
                    intérieures afin que tu puisses t'épanouir et te respecter.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub_container_light">
          <p className="citation">
            POUR BRILLER, IL FAUT D'ABORD CONNAITRE SES PROPRES COULEURS (ES) TU
            PRÊT (E) À LES DÉCOUVRIR AVEC MOI ?
          </p>
        </div>
        {/* <div className="sub_container_light justify">
          <h3>Signes que tu pourrais avoir besoin de mon aide</h3>
          <ul className="list">
            <li>
              <p>
                Tu vis régulièrement des échecs que ce soit socialement,
                humainement, relationnellement, émotionnellement.
              </p>
            </li>
            <li>
              <p>
                Tu ressens un décalage, une impression de trop ou de pas assez
                continuelle.
              </p>
            </li>
            <li>
              <p>
                Tu passes ta vie à essayer de te conformer aux attentes des
                autres
              </p>
            </li>
            <li>
              <p>Tu manques de confiance en toi, ou d'estime de toi.</p>
            </li>
            <li>
              <p>Tu es facilement fatigué, irritable, anxieux. </p>
            </li>
            <li>
              <p>Tu vis un épuisement émotionnel. </p>
            </li>
            <li>
              <p>Tu es vulnérable au surinvestissement dans les relations. </p>
            </li>
            <li>
              <p>Tu es sensible à l'anxiété, frustrées, désillusionnées. </p>
            </li>
            <li>
              <p>Tu manques de motivation, d'engagement dans tes projets. </p>
            </li>
            <li>
              <p>
                Tu rencontres des difficultés sociales, tu vis un sentiment de
                solitude.
              </p>
            </li>
          </ul>

          <div className="sub_container_light justify">
            <p>
              Ce qui peut avoir un impact significatif sur ton bien être et ta
              qualité de vie. Cela nécessite souvent un soutien adapté pour être
              surmonté.
            </p>
            <p>
              Alors que tu as en toi des pépites incroyables et quand on ne se
              connait pas, on ne sait pas comment appréhender tout ça..
            </p>
          </div>
        </div> */}
        {/* <div className="sub_container_light justify">
          <h3>Exploration des options</h3>
          <p>
            Quelles options as-tu pour t'affranchir aujourd'hui de tout ce qui
            te peine, te bouleverse, te freine ? C'est ce qu'on explore lors des
            séances de coaching. Le coaching t'aide à prendre de la hauteur, à
            prendre conscience de tes propres solutions, dans ta propre vie. Je
            ne vais pas te coller des solutions toutes faites qui très
            certainement ne te correspondront pas. C'est un accompagnement sur
            mesure.
          </p>

          <p>
            Toi avec ta palette multicolore qui vit les choses intensément, je
            te propose par mon coaching et la qualité de mon écoute empathique,
            soutenante et inconditionnellement chaleureuse, un espace sécurisé
            pour explorer ta sensibilité et ton fonctionnement sans jugement ni
            pression, un accompagnement novateur et complet en 3 temps qui
            combine à la fois du coaching, de la connaissance sur ce que tu vis,
            et des pratiques de régulation sensorielle et émotionnelle. Un
            accompagnement spécialisé qui allie à la fois, la tête, le cœur et
            le corps. Cet accompagnement te fera cheminer avec fierté vers ton
            épanouissement. C'est l'un des meilleurs investissements que tu
            feras pour toi-même.
          </p>
        </div>
        <div className="sub_container_light justify">
          <h3>Pour</h3>
          <ul className="list">
            <li>
              <p>
                Reconnaître et valoriser ton plein potentiel et ta sensibilité
                unique,
              </p>
            </li>
            <li>
              <p>
                Identifier tes stratégies personnelles afin de réguler tes
                émotions de manière constructive et trouver un équilibre
                émotionnel,
              </p>
            </li>
            <li>
              <p>
                Renforcer ta confiance en toi afin de naviguer avec succès dans
                un monde parfois écrasant,
              </p>
            </li>
            <li>
              <p>
                Surmonter la surstimulation, la procrastination, le manque de
                compréhension, le sentiment de décalage pour naviguer à travers
                ces défis et envisager des solutions essentielles et adaptées
                pour toi.
              </p>
            </li>
            <li>
              <p>
                Équilibrer sainement ta recherche de réalisation et ton bien
                être
              </p>
            </li>
            <li>
              <p>
                Embrasser pleinement qui tu es et te réaliser avec
                épanouissement et détermination.
              </p>
            </li>
          </ul>
        </div> */}
        {/* <div className="sub_container_light justify">
          <p>
            Je t'accompagne pour mieux te comprendre, trouver et prendre ta
            place, gérer les pressions, réguler ton stress et tes émotions,
            gagner en confiance, en acceptation, en affirmation, en estime, en
            amour de soi, fixer des limites, lutter contre la procrastination,
            le perfectionnisme, le syndrome de l'imposteur, s'épanouir dans ses
            relations….
          </p>

          <p>
            Moi-même HPI, hypersensible, multi passionnée et multi potentiel je
            sais, je connais ce que tu traverses pour l'avoir vécu moi-même. Et
            pour l'avoir accompagné chez mes élèves de tout âge dans tous les
            niveaux de classe de la maternelle au collège.
          </p>

          <p>
            J'ai fait et je poursuis ce travail d'introspection, de prise de
            hauteur, d'analyse, de prise de conscience qui me permet
            aujourd'hui, d'être ancrée, alignée avec mes besoins, mes valeurs.
            J'ai renforcé mes piliers qui sont maintenant solides. En bref, je
            connais parfaitement mes couleurs et je sais ce qui est bon pour
            moi, ce qui ne l'est pas. Je m'aime, me respecte et suis en capacité
            de poser mes limites pour moi et pour les autres. Je vis pleinement
            l'instant présent et je me projette vers un avenir que je construis
            en adéquation à mes couleurs.
          </p>

          <p>
            Ma contribution aujourd'hui c'est de t'aider à découvrir la palette
            unique de tes couleurs intérieures afin que tu puisses t'épanouir et
            te respecter.
          </p>
        </div> */}

        {/* <p className="citation">
          Pour briller, il faut d'abord connaître ses propres couleurs. Es-tu
          prêt à les découvrir avec moi ?
        </p> */}
        <div className="sub_container_light">
          <h3>CE QUI ME CARACTÉRISE :</h3>
          <div className="caracteristique">
            <div className="container_bulle">
              <div className="bulle">
                <p>D</p>
                <p>Discernement</p>
              </div>
              <p>Ma capacité à comprendre tes situations, leurs implications</p>
            </div>
            <div className="container_bulle">
              <div className="bulle">
                <p>E</p>
                <p>Engagement</p>
              </div>
              <p>
                Mon intérêt, ma motivation et ma détermination dans ce que
                j'entreprends avec toi.
              </p>
            </div>
            <div className="container_bulle">
              <div className="bulle">
                <p>L</p>
                <p>Loyauté</p>
              </div>
              <p>
                Mon profond respect pour l'autre et mon honnêteté dans mes
                interactions.
              </p>
            </div>
            <div className="container_bulle">
              <div className="bulle">
                <p>P</p>
                <p>Profondeur</p>
              </div>
              <p>
                Ma faculté à comprendre de manière profonde et nuancée, au-delà
                des apparences.
              </p>
            </div>
            <div className="container_bulle">
              <div className="bulle">
                <p>F</p>
                <p>Finesse</p>
              </div>
              <p>
                Mon écoute active très sensible aux détails et aux signaux non
                verbaux.
              </p>
            </div>
            <div className="container_bulle">
              <div className="bulle">
                <p>I</p>
                <p>Intérêt</p>
              </div>
              <p>
                Ma passion pour l'humain et la complexité des relations
                interpersonnelles.
              </p>
            </div>
            <div className="container_bulle">
              <div className="bulle">
                <p>N</p>
                <p>Naturel</p>
              </div>
              <p>Mon authenticité et mon honnêteté dans mes partages.</p>
            </div>
            <div className="container_bulle">
              <div className="bulle">
                <p>E</p>
                <p>Empathie</p>
              </div>
              <p>
                Ma compréhension de l'autre pour interagir de manière sensible
                et appropriée avec toi.
              </p>
            </div>
          </div>
          {/* <img
            className="illustration_1"
            src={`/image/ce_qui_me_caracterise.png`}
            alt={`ce qui me caractérise`}
          /> */}
          <div className="sub_container_light">
            <h3>MES ENGAGEMENTS :</h3>
            <ul id="list">
              {[
                'T’écouter avec empathie et sans jugement',
                'Mettre à ton service mes compétences, mes outils et tout ce qui me caractérise.',
                'T’accompagner vers tes objectifs et ton autonomie',
                'Garantir la confidentialité de nos échanges',
                'Respecter le code éthique et déontologique de l’organisme mondial ICF',
              ].map((text, index) => (
                <li key={index}>
                  <p>{text}</p>
                </li>
              ))}
            </ul>
          </div>

          <div className="sub_container_light">
            <h3>MES ANECDOTES :</h3>
            <ul id="list" className="list_anim">
              {[
                'J’ai aménagé un camion avec mon compagnon. On a tout fait nous même. C’est mon espace de ressourcement, mon cocon.',
                'J’adore apprendre, avoir plusieurs projets à la fois, découvrir de nouveaux horizons. C’est mon côté multi-potentiel !',
                'Je n’ai pas prévu de mourir. J’ai encore trop à faire !',
                'J’ai vécu en France, en Afrique, en Guyane, à l’Île de la Réunion. J’ai déménagé plus de 40 fois !',
                'Ma croyance aidante, c’est qu’à chaque fois que je me plante, je pousse. Je remercie ma formatrice CNV Michèle Guez pour cette pensée !',
                'Ma passion c’est créer. J’aime me dire que je peux imaginer ce que je veux et le faire.',
                'J’ai découvert le monde de la vanlife, l’ouverture aux autres, à la nature et la liberté d’être.',
                'Mon petit plaisir : c’est la lecture !',
                'Au début de ma vie d’adulte j’étais collaboratrice en cabinet d’expertise comptable, puis j’ai été enseignante (maternelle et élémentaire) et formatrice d’enseignants (réseaux d’éducation prioritaires et maths). Je ne mets pas tous mes diplômes ça ferait beaucoup 🙂',
                'Mon conseil c’est que  tu as tout en toi alors écoute !!!',
              ].map((text, index) => (
                <li key={index}>
                  <p>{text}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="sub_container_light">
            <h3>MES FORMATIONS :</h3>
            <ul id="list">
              {[
                'Diplôme de professeur des écoles de l’Éducation Nationale',
                'Certifiée coach professionnelle par l’intitut de coaching international de Genève accrédité ICF',
                'Certifiée coach spécialisée dans l’accompagnement de l’hypersensibilité et le haut potentiel intellectuel par Audrey Akoun',
                'Certifiée coach santé et bien être par l’institut de coaching international de Genève accrédité ICF',
                'Certifiée médiatrice CNV par l’organisme de formation « A partir de maintenant »',
                'Formée à la CNV et assistante de formation en CNV',
              ].map((text, index) => (
                <li key={index}>
                  <p>{text}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="sub_container_light">
            <h3>MA BOITE À OUTILS</h3>
            <ul id="list">
              {[
                'Coaching',
                'Médiation',
                'CNV (communication non violente)',
                'Hypnose symbolique',
                'PNL',
                '...',
              ].map((text, index) => (
                <li key={index}>
                  <p>{text}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default APropos
