import './Contact.scss'

import React from 'react'

function Contact() {
  return (
    <div className="contact_container">
      <h1>Contact Us</h1>
    </div>
  )
}

export default Contact
