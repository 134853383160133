import './LegalNotice.scss'

import React from 'react'

function LegalNotice() {
  return (
    <div className="legal_notice_container">
      <h1>Mentions Légales</h1>

      <div className="legal_notice_content">
        <div className="sub_container_light">
          <p>
            Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004
            pour la confiance en l'économie numérique, il est précisé aux
            utilisateurs du site DelPfine l'identité des différents intervenants
            dans le cadre de sa réalisation et de son suivi.
          </p>
        </div>
        <div className="sub_container_light indent">
          <h2>Edition du site</h2>
          <p className="indent">
            Le présent site, accessible à l'URL{' '}
            <a href="https://www.delpfine.com">www.delpfine.com</a> (le « Site
            »), est édité par :
          </p>
          <p className="indent">
            Delphine Lafontaine, de nationalité Française (France), inscrite au
            R.C.S. de VIENNE sous le numéro RCS VANNES B 514 919 844,
          </p>
          <p className="indent">
            Le numéro individuel TVA de l'éditeur est : FR 40 123456789.
          </p>
        </div>

        <div className="sub_container_light">
          <h2>Hébergement</h2>
          <p className="indent">
            Le Site est hébergé par la société Hostinger, situé 2 rue de
            l'échappée 75010 PARIS., (contact téléphonique ou email :
            +33612345678).
          </p>
        </div>
        <div className="sub_container_light">
          {' '}
          <h2>Directeur de publication</h2>
          <p className="indent">
            Le Directeur de la publication du Site est Delphine Lafontaine.
          </p>
        </div>

        <div className="sub_container_light">
          <h2>Nous contacter</h2>
          <p className="indent">Par téléphone : +337 67 94 42 21</p>
          <p className="indent">
            Par email :{' '}
            <a href="mailto:contact@delpfine.com">contact@delpfine.com</a>
          </p>
          <p className="indent">
            Par courrier : 112 rue de la petite jonquille 12345 BELLE FLEUR
          </p>
        </div>
        <div className="sub_container_light">
          <h2>Données personnelles</h2>
          <p className="indent">
            Le traitement de vos données à caractère personnel est régi par
            notre Charte du respect de la vie privée, disponible depuis la
            section "Charte de Protection des Données Personnelles",
            conformément au Règlement Général sur la Protection des Données
            2016/679 du 27 avril 2016 («RGPD»).
          </p>

          <p className="indent">
            Delphine Lafontaine a désigné un Délégué à la Protection des Données
            (DPO) auprès de la CNIL (Désignation N° ). Les coordonnées de notre
            Délégué à la Protection des Données sont les suivantes :
          </p>
        </div>

        <div className="sub_container_light">
          <p className="indent">
            Nom : Arthur Martin <br></br>Adresse : 2 rue de l'échappée 75010
            PARIS <br></br> Téléphone : +33451815514 <br></br> Email :{' '}
            <a href="mailto:nom@exemple.com">nom@exemple.com</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default LegalNotice
