import './Header.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from '../../assets/icons/logo_delpfine.webp'
import burgerMenu from '../../assets/icons/burger-menu.svg'
import logoInvert from '../../assets/icons/logo_delpfine_invert_no_bg.webp'

function Header() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const headerRef = useRef(null)

  const [activeLink, setActiveLink] = useState(location.pathname)

  // const handleLanguageChange = (event) => {
  //   const newLanguage = event.target.value
  //   console.log('change langue : ', newLanguage)
  //   i18n.changeLanguage(newLanguage)
  // }

  const handleLogoClick = () => {
    setIsMenuOpen(false)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setActiveLink()
    navigate('/')
  }

  const handleRedirect = (path) => {
    setIsMenuOpen(false)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setActiveLink(path)
  }

  const toggleDisplayMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  useEffect(() => {
    const handleScroll = () => {
      const linksContainer = document.querySelector('.links_container')
      if (window.scrollY >= 150) {
        headerRef.current.classList.add('sticky-header')
        document.body.style.paddingTop = '300px'
      } else {
        headerRef.current.classList.remove('sticky-header')
        document.body.style.paddingTop = '0'
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    setActiveLink(location.pathname)
  }, [location.pathname])

  return (
    <header className="header_container" ref={headerRef}>
      <nav>
        {/* <h1>DelpFine</h1> */}
        <div className="logo_container">
          <img
            className="delpfine_logo"
            src={isMenuOpen ? logoInvert : logo}
            alt="Logo DelpFine"
            height={120}
            onClick={() => handleLogoClick()}
          />
          <div className="titles_container">
            <h3 style={{ color: isMenuOpen ? 'white' : '' }}>
              COACH DE VIE PRO & PERSO CERTIFIÉE COACH PROFESSIONNEL
            </h3>
            <h4 style={{ color: isMenuOpen ? 'white' : '' }}>
              HYPERSENSIBLE - HAUT POTENTIEL - MULTIPOTENTIEL - SANTÉ ET
              BIEN-ÊTRE
            </h4>
            <p style={{ color: isMenuOpen ? 'white' : '' }}>
              Par l'institut de coaching international de Genève organisme de
              formation accrédité ICF
            </p>
          </div>
        </div>
        <div className={`links_container ${isMenuOpen ? 'active' : ''}`}>
          <Link
            className={activeLink === '/a-propos' ? 'active-link' : ''}
            to="/a-propos"
            onClick={() => handleRedirect('/a-propos')}
          >
            <p> {t('about')}</p>
          </Link>
          <Link
            className={activeLink === '/explore' ? 'active-link' : ''}
            to="/explore"
            onClick={() => handleRedirect('/explore')}
          >
            <p>{t('explore_how_it_works')}</p>
          </Link>
          <Link
            className={activeLink === '/suivi' ? 'active-link' : ''}
            to="/suivi"
            onClick={() => handleRedirect('/suivi')}
          >
            <p>{t('accompaniement')}</p>
          </Link>
          <Link
            className={activeLink === '/ressources' ? 'active-link' : ''}
            to="/ressources"
            onClick={() => handleRedirect('/ressources')}
          >
            <p>{t('ressources')}</p>
          </Link>
          <a
            className="underline reserver_seance"
            href="https://zcal.co/delpfine/30min"
          >
            <p>{t('reservation')}</p>
          </a>
        </div>
        <img
          className="menu-hamburger"
          src={burgerMenu}
          alt="burger menu icon"
          height={35}
          onClick={toggleDisplayMenu}
          style={{ filter: isMenuOpen ? 'invert(100%)' : 'none' }}
        />
        {/* <div
          className="language_selector"
          style={{
            position: isMenuOpen ? 'fixed' : '',
            bottom: isMenuOpen ? '1em' : '',
          }}
        >
          <FontAwesomeIcon
            icon={faGlobe}
            style={{ color: isMenuOpen ? 'white' : '' }}
          />
          <select
            style={{
              color: isMenuOpen ? 'white' : '',
            }}
            value={i18n.language}
            onChange={handleLanguageChange}
          >
            {['fr', 'en', 'it', 'ge'].map((language) => (
              <option
                key={language}
                style={{
                  backgroundColor: isMenuOpen ? '#003f3f' : '',
                }}
                value={language}
              >
                {language === 'fr' && 'Français'}
                {language === 'en' && 'English'}
                {language === 'it' && 'Italian'}
                {language === 'ge' && 'German'}
              </option>
            ))}
          </select>
        </div> */}
      </nav>
      {/* <div className="container_chevron">
        <div className="chevron"></div>
        <div className="chevron"></div>
        <div className="chevron"></div>
      </div> */}
    </header>
  )
}

export default Header
